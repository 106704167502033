import * as Yup from 'yup';

const validatePassword = (value) => {
  if (!value) {
    return true;
  }
  const hasLetter = /[a-z]/.test(value);
  const hasCapitalLetter = /[A-Z]/.test(value);
  const hasNumber = /\d/.test(value);
  const hasSymbol = /[@$!%*?&]/.test(value);
  const isValidLength = value.length >= 9 && value.length <= 20;

  if (!hasLetter) {
    return 'Password must contain at least one letter';
  }

  if (!hasCapitalLetter) {
    return 'Password must contain at least one capital letter';
  }

  if (!hasNumber) {
    return 'Password must contain at least one number';
  }

  if (!hasSymbol) {
    return 'Password must contain at least one symbol';
  }

  if (!isValidLength) {
    return 'Password must be between 9 and 20 characters';
  }

  return true; // Password is valid
};


export const loginValidationSchema = Yup.object({
  email: Yup.string()
    .required('Email is required')
    .email('Invalid email format'),
  password: Yup.string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters')

});

export const setPasswordValidation = Yup.object({
  email: Yup.string().required('Enter your email').email('Invalid email format'),
  newPassword: Yup.string()
    .required('Password is required')
    .test('password-strength', 'Password is not strong enough', (value) => {
      const result = validatePassword(value);
      return result === true ? true : new Yup.ValidationError(result, null, 'newPassword');
    }),
  confirmPassword: Yup.string()
    .required('Confirm your password')
    .oneOf([Yup.ref('newPassword')], 'The two passwords do not match'),
});

export const forgotPassword = Yup.object({
  email: Yup.string()
    .required('Email is required')
    .email('Invalid email format'),
});

export const otpValidation = Yup.object({
  verificationCode: Yup.number()
  .typeError('OTP must be a number')
  .required('Enter the OTP')
});

export const myValidationSchema = Yup.object({
  mySelect: Yup.string()
    .required('Select an option'),
  myField: Yup.string()
    .required('Name is required'),
  agree: Yup.bool()
    .oneOf([true], 'You must agree to the terms and conditions'),
  myRadioGroup: Yup.string()
    .required('Select an option'),
});


export const CreateEditCataValidation = Yup.object({
  categoryName: Yup.string()
    .required('Enter category name')
});

export const CreateEditRfValidation = Yup.object({
  badgeMacNumber: Yup.string()
    .required('Enter Rfid number')
});

export const CreateEditlocValidation = Yup.object({
  location: Yup.string()
    .required('Enter location name'),
  storeId: Yup.number()
    .required('Store is Required'),
});

export const CreateEditProdValidation = Yup.object().shape({
  products: Yup.array().of(
    Yup.object().shape({
      productName: Yup.string().required('Enter Product name'),
      categoryId: Yup.number().required('Enter category id'),
      productId: Yup.string().required('Enter product id'),
      locationId: Yup.number().required('Enter location id'),
      productPrice: Yup.number()
      .typeError('Product price must be a number').required('Enter product price')
      .positive('Product price must be a positive number')
      .min(1, 'Product price must be greater than 0'),
      rfidNumberId: Yup.number().required('Enter rfid number'),
    })
  )
});

export const bulkUploadValidation = Yup.object().shape({
  products: Yup.array().of(
    Yup.object().shape({
      productName: Yup.string().required('Enter Product name'),
      categoryName: Yup.string().required('Enter category name'),
      // productId: Yup.string().required('Enter Product ID'),
      location: Yup.string().required('Enter location name'),
      badgeMacNumber: Yup.string().required('Enter Rfid Tag Number'),
      // productPrice: Yup.number()
      //   .typeError('Product price must be a number')
      //   .required('Enter product Price')
      //   .positive('Product price must be a positive number')
      //   .min(1, 'Product price must be greater than 0'),
    })
  )
});

export const createUserValidation = Yup.object({
  firstName: Yup.string().required('Enter first name'),
  lastName: Yup.string().required('Enter last name'),
  email: Yup.string().required('Enter your email').email('Invalid email format'),
  password: Yup.string()
    .required('Password is required')
    .test('password-strength', 'Password is not strong enough', (value) => {
      const result = validatePassword(value);
      return result === true ? true : new Yup.ValidationError(result, null, 'password');
    }),
  roleId: Yup.number()
    .required('Role is required')
    .integer('Role must be an integer')
    .positive('Role must be a positive number')
});

export const editUserValidation = Yup.object({
  firstName: Yup.string().required('Enter first name'),
  lastName: Yup.string().required('Enter last name'),
  email: Yup.string().required('Enter your email').email('Invalid email format'),
  password: Yup.string()
    .required('Password is required')
    .test('password-strength', 'Password is not strong enough', (value) => {
      const result = validatePassword(value);
      return result === true ? true : new Yup.ValidationError(result, null, 'password');
    }),
  confirmPassword: Yup.string()
    .required('Confirm your password')
    .oneOf([Yup.ref('password')], 'The two passwords do not match'),
  roleId: Yup.number()
    .required('Role is required')
    .integer('Role must be an integer')
    .positive('Role must be a positive number')
});


export const createEditRoleValidation = Yup.object({
  roleName: Yup.string()
    .required('Enter Role name'),
 
});
