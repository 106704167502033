import React, { Suspense, lazy, useEffect } from 'react';
import AppLayouts from './layouts/appLayouts';
import AuthLayouts from './layouts/authLayouts';
import { useContextHelper } from 'helpers/ContextHelper';
import { useProfileDetails } from 'hooks/profile/profile';
import BulkUpload from 'views/app/configuration/product/builkUpload';
import ProtectedRoute from './component/shared-component/protected-route';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import globalRouter from './globalRouter.ts';


const Login = lazy(() => import('./views/auth/login'));
const Forgot = lazy(() => import('./views/auth/forgot'));
const SetPassword = lazy(() => import('./views/auth/set-password'));
const Reports = lazy(() => import('./views/app/reports'));

// configuration
const AssignRFID = lazy(() => import('./views/app/configuration/rfid'));
const CreateProduct = lazy(() => import('./views/app/configuration/product/create/index.jsx'));
const EditProduct = lazy(() => import('./views/app/configuration/product/edit/index'));
const Product = lazy(() => import('./views/app/configuration/product'));
const Category = lazy(() => import('./views/app/configuration/category'));
const Location = lazy(() => import('./views/app/configuration/location'));
const Inventory = lazy(() => import('./views/app/inventory'));
const ScannedProduct = lazy(() => import('./views/app/inventory/scanned-Product'));
const Admin = lazy(() => import('./views/app/user/admin'));
const Role = lazy(() => import('./views/app/user/role'));

const App = () => {
  const navigate = useNavigate();
  globalRouter.navigate = navigate;
  const { setProfile } = useContextHelper();
  const { data: profileData, refetch } = useProfileDetails();

  useEffect(() => {
    if (profileData) {
      setProfile(profileData?.data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileData]);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route path="/" element={<AuthLayouts />}>
          <Route index element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<Forgot />} />
          <Route path="/setpassword" element={<SetPassword />} />
        </Route>

        <Route element={<ProtectedRoute roles={['ADMIN']} />}>
          <Route element={<AppLayouts initialRefetch={refetch} />}>
            <Route index element={<Reports />} />
            <Route path="/reports" element={<Reports />} />
            <Route path="/configuration" element={<Navigate to="/configuration/rfid" />} />
            <Route path="/configuration/rfid" element={<AssignRFID />} />
            <Route path="/configuration/product" element={<Product />} />
            <Route path="/configuration/product/add" element={<CreateProduct />} />
            <Route path="/configuration/product/edit" element={<EditProduct />} />
            <Route path="/configuration/product/bulk-upload" element={<BulkUpload />} />
            <Route path="/configuration/category" element={<Category />} />
            <Route path="/configuration/location" element={<Location />} />
            <Route path="/inventory" element={<Inventory />} />
            <Route path="/inventory/:id" element={<ScannedProduct />} />
            <Route path="/user/admin" element={<Admin />} />
            <Route path="/user/roles" element={<Role />} />
          </Route>
        </Route>

        <Route element={<ProtectedRoute roles={['user']} />}>
          <Route path="/change" element={<AppLayouts />}>
            <Route index element={<Login />} />
          </Route>
        </Route>

      </Routes>
    </Suspense>
  );
};

export default App;



