import React, { Fragment, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MyButton from "component/shared-component/form/MyButton";
import { bulkUploadValidation } from "helpers/ValidationHelper";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSnackbar } from "component/shared-component/snackbar";
import MyTextField from "component/shared-component/form/MyTextField";
import MyPagination from "component/shared-component/form/MyPagination";
import { useUpdateBulkUpload} from "hooks/cofiguration/product/product";
import ConfigHeader from "component/shared-component/headers/configHeader";

const BulkUpload = () => {
    let location = useLocation();
    let navigate = useNavigate();
    
    const { showSnackbar } = useSnackbar();
   
    const [itemsPerPage] = useState(10);
    const [bulkData, setBulkData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    
    const { control, handleSubmit, reset, formState: { errors }, setValue, trigger } = useForm({
        resolver: yupResolver(bulkUploadValidation),
        defaultValues: { products: location.state }
    });

   
    const { mutate: updateBulkUpload } = useUpdateBulkUpload();

    const handleFieldChange = (index, field, value) => {
        setValue(`products[${index}].${field}`, value, { shouldValidate: true });
        trigger();
        const error = errors.products?.[index]?.[field];
        if (error) {
            updateBulkDataError(index, field, error.message);
        } else {
            updateBulkDataError(index, field, '');
        }
    };

    const updateBulkDataError = (index, field, message) => {
        setBulkData(prevData => {
            const updatedData = [...prevData];
            updatedData[index] = {
                ...updatedData[index],
                [`${field}Message`]: message
            };
            return updatedData;
        });
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = bulkData.slice(indexOfFirstItem, indexOfLastItem);


    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const handleUpdateProduct = (values) => {
        console.log(values);
        
        updateBulkUpload(JSON.stringify(values), {
            onSuccess: (item) => {
                if (item.statusCode === 200) {
                    showSnackbar(item?.message, 'success');
                    navigate("/configuration/product");
                    reset();
                } else {
                    showSnackbar(item?.message, 'error');
                }
            },
            onError: (error) => {
                showSnackbar(error?.message, 'error');
            }
        });
    }

    const onSubmit = (values) => {
        const clearErrorMessages = removeErrorMessages(values.products);
        handleUpdateProduct(clearErrorMessages);
    }

    const removeErrorMessages = (data) => {
        return data.map(item => {
            const {
                productIdMessage,
                productNameMessage,
                categoryNameMessage,
                locationMessage,
                badgeMacNumberMessage,
                productPriceMessage,
                ...removedItem
            } = item;
            return removedItem;
        });
    };

    useEffect(() => {
        if (location.state.length !== 0) {
            setBulkData(location.state);
            reset({ products: location.state });
        }
    }, [location.state, reset]);

    useEffect(() => {
        if (currentPage > Math.ceil(bulkData.length / itemsPerPage)) {
            setCurrentPage(1);
        }
    }, [bulkData, currentPage, itemsPerPage]);


    return (
        <Fragment>
            <ConfigHeader />
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="config-create-main">
                    <div className="inner-header createEdit-header">
                        <div className="title">
                            <Link to={`/configuration/product`}>
                                <div className="nav-back">
                                    <ArrowBackIcon />
                                    <span>{`Back to Product`}</span>
                                </div></Link>
                            <h1>Bulk Upload</h1>
                            <p>Add multiple products at once.</p>
                        </div>
                        <div className="txt-right single-line">
                        <MyButton
                            variant="contained"
                            color="primary"
                            size="medium"
                            fontSize="14px"
                            type="submit"
                        >Verify & Update
                        </MyButton>
                    </div>
                    </div>
                    <br />
                </div>
                <div className='common-table bulk-upload-table'>
                    <table>
                        <thead>
                            <tr>
                                <th>Product Name</th>
                                <th>Category Name</th>
                                {/* <th>Product Id</th> */}
                                <th>Location</th>
                                <th>RFID Tag</th>
                                {/* <th>Product Price</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.map((item, index) => (
                                <tr key={index + indexOfFirstItem}>
                                    <td>
                                        <MyTextField
                                            name={`products[${index + indexOfFirstItem}].productName`}
                                            control={control}
                                            rules={{ required: 'Enter product name' }}
                                            label=""
                                            autoComplete="off"
                                            error={!!errors.products?.[index]?.productName}
                                            errorMessage={errors.products?.[index]?.productName?.message || item.productNameMessage}
                                            placeholder="Enter product name"
                                            onChange={(e) => handleFieldChange(index, 'productName', e.target.value)}
                                        />
                                    </td>
                                    <td>
                                        <MyTextField
                                            name={`products[${index + indexOfFirstItem}].categoryName`}
                                            control={control}
                                            rules={{ required: 'Enter category Name' }}
                                            label=""
                                            autoComplete="off"
                                            error={!!errors.products?.[index + indexOfFirstItem]?.categoryName}
                                            errorMessage={errors.products?.[index + indexOfFirstItem]?.categoryName?.message || item.categoryNameMessage}
                                            placeholder="Enter category Name"
                                            onChange={(e) => handleFieldChange(index + indexOfFirstItem, 'categoryName', e.target.value)}
                                        />
                                    </td>
                                    {/* <td>
                                        <MyTextField
                                            name={`products[${index + indexOfFirstItem}].productId`}
                                            control={control}
                                            rules={{ required: 'Enter Product ID' }}
                                            label=""
                                            autoComplete="off"
                                            error={!!errors.products?.[index + indexOfFirstItem]?.productId}
                                            errorMessage={errors.products?.[index + indexOfFirstItem]?.productId?.message || item.productIdMessage}
                                            placeholder="Enter Product ID"
                                            onChange={(e) => handleFieldChange(index + indexOfFirstItem, 'productId', e.target.value)}
                                        />
                                    </td> */}
                                    <td>
                                        <MyTextField
                                            name={`products[${index + indexOfFirstItem}].location`}
                                            control={control}
                                            rules={{ required: 'Enter location name' }}
                                            label=""
                                            autoComplete="off"
                                            error={!!errors.products?.[index + indexOfFirstItem]?.location}
                                            errorMessage={errors.products?.[index + indexOfFirstItem]?.location?.message || item.locationMessage}
                                            placeholder="Enter location name"
                                            onChange={(e) => handleFieldChange(index + indexOfFirstItem, 'location', e.target.value)}
                                        />
                                    </td>
                                    <td>
                                        <MyTextField
                                            name={`products[${index + indexOfFirstItem}].badgeMacNumber`}
                                            control={control}
                                            rules={{ required: 'Enter RFID Tag Number' }}
                                            label=""
                                            autoComplete="off"
                                            error={!!errors.products?.[index + indexOfFirstItem]?.badgeMacNumber}
                                            errorMessage={errors.products?.[index + indexOfFirstItem]?.badgeMacNumber?.message || item.badgeMacNumberMessage}
                                            placeholder="Enter RFID Tag Number"
                                            onChange={(e) => handleFieldChange(index + indexOfFirstItem, 'badgeMacNumber', e.target.value)}
                                        />
                                    </td>
                                    {/* <td>
                                        <MyTextField
                                            name={`products[${index + indexOfFirstItem}].productPrice`}
                                            control={control}
                                            rules={{ required: 'Enter product Price' }}
                                            label=""
                                            autoComplete="off"
                                            error={!!errors.products?.[index + indexOfFirstItem]?.productPrice}
                                            errorMessage={errors.products?.[index + indexOfFirstItem]?.productPrice?.message || item.productPriceMessage}
                                            placeholder="Enter product Price"
                                            onChange={(e) => handleFieldChange(index + indexOfFirstItem, 'productPrice', e.target.value)}
                                        />
                                    </td> */}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </form>
            <div className="common-pagination">
                <MyPagination
                    count={Math.ceil(bulkData.length / itemsPerPage)}
                    page={currentPage}
                    onPageChange={handlePageChange}
                    siblingCount={1}
                    boundaryCount={1}
                    variant=""
                    shape="rounded"
                />
            </div>
        </Fragment>
    );
};

export default BulkUpload;
