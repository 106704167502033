import NetworkClient from "helpers/networkClient";
import NetworkmultipartClient from "helpers/networkMultiPartClient";



const productList = async (formData) => {
    const { data } = await NetworkClient.post("product/productList", formData);
    return data;
}
const addProduct = async (formData) => {
    const { data } = await NetworkClient.post("product/addProduct", formData);
    return data;
}
const editProduct = async (formData) => {
    const { data } = await NetworkClient.put("product/editProduct", formData);
    return data;
}
const deleteProduct = async (formData) => {
    const { data } = await NetworkClient.delete(`product/${formData}`);
    return data;
}
const statusChange = async (formData) => {
    const { data } = await NetworkClient.put(`product/changeStatus/${formData}`);
    return data;
}
const prodRfidDropdown = async () => {
    const { data } = await NetworkClient.get("rfid/dropdown");
    return data;
}
const prodCategoryDropdown = async () => {
    const { data } = await NetworkClient.get("category/dropdown");
    return data;
}
const prodLocationDropdown = async () => {
    const { data } = await NetworkClient.get("location/dropdown");
    return data;
}
const bulkUpload = async (formData) => {
    const { data } = await NetworkmultipartClient.post(`/product/bulkUpload/readCsv`, formData);
    return data;
}

const updateBulkUpload = async (formData) => {
    const { data } = await NetworkClient.post(`/product/bulkUpload/uploadCsvData`, formData);
    return data;
}


export { productList, addProduct, editProduct, deleteProduct, statusChange, prodRfidDropdown, prodCategoryDropdown, prodLocationDropdown, bulkUpload, updateBulkUpload }
